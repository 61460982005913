import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Avatar, Box, Button, ButtonBase, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { useNewAuth } from 'src/hooks/use-auth-new';
import { UserRolesViewModel } from '@the-mcorp/elevenfiftynine-srs-lib';
import { AccountPopover } from '../account-popover';
import { usePopover } from 'src/hooks/use-popover';

export interface AccountIconsProps {}

/**
 * Renders the account icons in the navbar content.
 * @component
 * @param {AccountIconsProps} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
const AccountIcons: FC<AccountIconsProps> = (props) => {
	const { user, roles } = useNewAuth();
	const [username, setUsername] = useState(null);
	const [userEmail, setUserEmail] = useState(null);
	const [isAdmin, setIsAdmin] = useState(false);
	const [userinfo, setuserInfo] = useState(null);
	const iconColor = 'secondary';
	const popover = usePopover<HTMLButtonElement>();

	/** API Configuration & Startup Services */
	useEffect(() => {
		if (user && roles && roles.length > 0 && user.username) {
			const userRoles: UserRolesViewModel[] = roles;
			const srsUser = userRoles.find((role) => role.isValidSRSUser == true);

			setIsAdmin((userRoles.find((role) => role.isAdmin == true) !== null) !== null);
			setUsername(srsUser.fullName ? srsUser.fullName : user.username);
			setUserEmail(srsUser?.emailAddress ? srsUser?.emailAddress : user?.emails[0]);
			setuserInfo(srsUser);
		}
	}, [roles]);

	

	const getAvatarText = () => {
		if (userinfo) {
			let avatarText = '';
			if (userinfo.givenName) {
				avatarText += userinfo.givenName.charAt(0);
			}
			if (userinfo.familyName) {
				avatarText += userinfo.familyName.charAt(0);
			}
			return avatarText.toUpperCase();
		}
		else if (username) {
			const names = username.split(' ');
			if (names.length > 1) {
				return names[0].charAt(0).toUpperCase() + names[1].charAt(0).toUpperCase();
			} else {
				return names[0].charAt(0).toUpperCase();
			}
		}
		return '';

	}
	const popoverProps = {
		anchorEl: popover.anchorRef.current,
		onClose: popover.handleClose,
		open: popover.open,
		userinfo: { username: username, userEmail: userEmail },
		avatarText: getAvatarText()
	};
	function stringToColor(string: string) {
		let hash = 0;
		let i;
	  
		/* eslint-disable no-bitwise */
		for (i = 0; i < string.length; i += 1) {
		  hash = string.charCodeAt(i) + ((hash << 5) - hash);
		}
	  
		let color = '#';
	  
		for (i = 0; i < 3; i += 1) {
		  const value = (hash >> (i * 8)) & 0xff;
		  color += `00${value.toString(16)}`.slice(-2);
		}
		/* eslint-enable no-bitwise */
	  
		return color;
	  }

	return (
		<>
			{username && (
				<>
					<Button
						color='inherit'
						ref={popover.anchorRef}
						onClick={popover.handleOpen}
						size='small'
						sx={{
							alignItems: 'center',
							display: 'flex',
							
						}}>
						<Avatar sx={{bgcolor: stringToColor(username)}}>{getAvatarText()}</Avatar>
					</Button>
					<AccountPopover {...popoverProps} />
				</>
			)}
		</>
	);
};

export default AccountIcons;
